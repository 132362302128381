export const googleform = [
    {
        // データサイエンティスト育成講座 オンライン説明会
        tag: "school_introduction",
        name: "データミックス スクール無料説明会※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSdpMOQi15wuZDaIvNWwnNFK-ouFFG9XGKur9vrklf6OL_sSCg/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // 気象データアナリスト養成講座 オンライン説明会
        tag: "weather_da_introduction",
        name: "気象データアナリスト養成講座　無料説明会　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSfzHIcaeTwsfEq-7r8YY3Z_T9PAc7hDHor6ycSjWnVXdNwWew/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // HRアナリスト養成講座 オンライン説明会
        tag: "hr_da_introduction",
        name: "HRアナリスト養成講座　無料説明会　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSdwTJu462rPdM9a5LigKN2nHZ84rPs8vdW4TMf4Tlh30DksNQ/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // プロダクトデータアナリスト育成講座 オンライン説明会
        tag: "product_da_introduction",
        name: "プロダクト・データアナリスト育成講座　無料説明会　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSfm3gbOi5VlC0LfQaf-P8nC1nff0hjidd8b-L7UpaPYsFe_-A/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // データエンジニア・MLエンジニア講座 オンライン説明会
        tag: "de_mle_introduction",
        name: "データエンジニア・MLエンジニア講座　無料説明会　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSd40_YVyPafiTwzSu7K1Vxe2wGZUJOWjZX4pFWVaFJDcEcPjA/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // 経営変革アナリスト育成講座 オンライン説明会
        tag: "bta_introduction",
        name: "経営変革アナリスト育成講座　無料説明会　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLScPY0SUr_SeiFXJKw8I2F4sLEXadthg7Ts1OSs1OyLfCI6vTw/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // データサイエンティスト育成講座 無料体験講座
        tag: "ikusei-trial-lesson",
        name: "データサイエンティスト育成講座　無料体験講座/説明会　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSe2T_a6k-3tHQ1JcyVHLCnU9awudLzJg89MryW7v1sAKtAIJg/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // ゼロから学ぶPythonプログラミング WS
        tag: "python_program",
        name: "【無料】ゼロから学ぶPythonプログラミング※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSd2TkN6CL7A7Dli2byMRXOidZfR1hgZzireSOlV1sf_EhEL-Q/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // オープンキャンパス
        tag: "open-school",
        name: "【無料イベント】データミックス オープンキャンパス",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSd1xdCCvCpF9rqizZ6aTwvZbreYv0ZMME5LkDE2xIe5gPSHag/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // 「営業」と「データサイエンス」 の融合が、営業組織をリードするビジネスリーダーになる鍵 セミナー
        tag: "online-event-sales",
        name: "【無料イベント】「営業」と「データサイエンス」 の融合が、営業組織をリードするビジネスリーダーになる鍵 ※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSd3PP1a2Y8ajbhvE8uqmpk_DSCqeDmw1Aq9_BeywYRE0BVgDg/viewform?embedded=true",
        width: "100%",
        height: "2000",
    },
    // {
    //     // データミックス卒業生が語る 「データサイエンス学習後の実務・キャリアへの活かし方」 セミナー
    //     tag: "ikusei_zadankai-grad",
    //     name: "【無料イベント】データミックス卒業生が語る 「データサイエンス学習後の実務・キャリアへの活かし方」",
    //     price: 0,
    //     url: "https://docs.google.com/forms/d/e/1FAIpQLScEkk10ZkeZ0TIH_b4NGcNefLwB_meM8cvmP5N6R0U_JJgP-Q/viewform?embedded=true",
    //     width: "100%",
    //     height: "2000",
    // },
    {
        // データサイエンス人材の転職・副業の “今” セミナー
        tag: "online-event-career",
        name: "【無料イベント】データサイエンス人材の転職・副業の “今”　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSewz2jegWvMsGCEjtnOVJHt-2N9SDBHq9tDbDMkuWoPjxJ0Zw/viewform?embedded=true",
        width: "100%",
        height: "2000",
    },
    {
        // 2040年の未来予測と今からできるリスキリング セミナー
        tag: "online-event-reskill",
        name: "【無料イベント】2040年の未来予測と今からできるリスキリング　※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSdk4rnXJi6IJBoAEngy3qmLlwMqdo9ryqFXXkmaoYQYf8vdZg/viewform?embedded=true",
        width: "100%",
        height: "2000",
    },
    {
        // 進化するスポーツDXの世界「スポーツアナリティクスとは」 セミナー
        tag: "online-event-sports",
        name: "【無料イベント】進化するスポーツDXの世界「スポーツアナリティクスとは」",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSeCAV55Jmtoc6BYTzcxLaXdtIsPWnxORPLDnor-Nmpy_699dw/viewform?embedded=true",
        width: "100%",
        height: "2000",
    },
    {
        // AI時代到来でデータサイエンティストの役割は終わりか、それとも始まりか？ セミナー
        tag: "online-event-datascientist",
        name: "【無料イベント】AI時代到来でデータサイエンティストの役割は終わりか、それとも始まりか？",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLScM4-jSVucCytwUpBjK_r_QszWN3wBsKNlHX8Bw38dF97IMrQ/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // スクール無料個別受講相談会
        tag: "ikusei-session",
        name: "スクール無料個別受講相談会　※オンライン",
        price: 0,
        type: "calendar",
        url: "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3DBhk67Ep95-3xUFK73lHXnr5KXQuIBnyh9m-9pbL60FWdLh1kfA3vZ2vIvxt3qvxKanrMFaiO?gv=true",
        width: "100%",
        height: "1000",
    },
    {
        // 中学数学から始める生成AIの仕組み セミナー
        tag: "online-event-genai-intro",
        name: "【無料イベント】中学数学から始める生成AIの仕組み",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLScmhnnG55PyN3yMme8rN_tkH4b62BcEH3AX7R8sU3E_aVF3lw/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // AI・データサイエンスは独学で身につくのか？ セミナー
        tag: "online-event-self-learning",
        name: "【無料イベント】AI・データサイエンスは独学で身につくのか？",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSdyEXjRBK9proMZRlJbddAzCnE5JYQipxmdk8AJq3wkhubbqQ/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // ChatGPT活用！最短ルートで学ぶ機械学習入門 セミナー
        tag: "online-event-chatgpt-ml",
        name: "【無料イベント】ChatGPT活用！最短ルートで学ぶ機械学習入門",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSezttpdniQX-HdWkHTgNVs76e5m8DANfpxjryeO9ZmzHTlsTA/viewform?embedded=true",
        width: "100%",
        height: "1800",
    },
    {
        // 生成AIを使って1時間でデータ分析コンペティションKaggleに挑戦！ セミナー
        tag: "online-event-chatgpt-kaggle",
        name: "【無料イベント】生成AIを使って1時間でデータ分析コンペティションKaggleに挑戦！",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSd0E6P5zKFZ-xXmqkoPjCWnVcRjUEF7ptDC0pjVQT0jKgv_ig/viewform?embedded=true",
        width: "100%",
        height: "1500",
    },
    {
        // 卒業生向け統合説明会
        tag: "graduatecourse_introduction",
        name: "データミックス　卒業生向け統合説明会※オンライン",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSdzuh3EjGKTLyE4ePsZqukCAhKpnEcc-6Kdkg_iO6u85R7xCA/viewform?embedded=true",
        width: "100%",
        height: "1500",
    },
    {
        // 【無料イベント】データサイエンスとAIで仕事をアップデートする　- 事例で理解するあなたが次に目指すキャリアとは-
        tag: "online-event-updateyourcareer",
        name: "【無料イベント】データサイエンスとAIで仕事をアップデートする　- 事例で理解するあなたが次に目指すキャリアとは-",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSfBBHmhQnCQitNBdhrHgnDgthr7-3xGHFzyudMnn68fbmZMqQ/viewform?embedded=true",
        width: "100%",
        height: "1600",
    },
    {
        // データミックス卒業発表会
        tag: "grad_presentation",
        name: "データミックス卒業発表会",
        price: 0,
        type: "form",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSck35KgxmY_fukuP93l9DlRxZdUX8q4AmT1IY4Dk3N4WPUBPg/viewform?embedded=true",
        width: "100%",
        height: "3200",
    },
    // // sample
    // {
    //     // sample
    //     tag: "sample",
    //     name: "サンプル",
    //     price: 0,
    //     type: "form",
    //     url: "https://docs.google.com/forms/d/e/1FAIpQLScze1ALr6J-nQberE_451bf-gR_hLBnTopxHnrfAiEoMfDbng/viewform?embedded=true",
    //     width: "100%",
    //     height: "1800",
    // },
    // {
    //     // sample
    //     tag: "sample-calendar",
    //     name: "サンプル-カレンダー",
    //     price: 0,
    //     type: "calendar",
    //     url: "https://calendar.google.com/calendar/appointments/schedules/AcZssZ0qL4YtDKU528BzpHcTg7iYqzh80bmFK2UfNpBwsuNm1qyPJk7FyGOW220d9Wf6QN37wuKGX9pv?gv=true",
    //     width: "100%",
    //     height: "1000",
    // },
]

export const salesformUrl = "https://go.datamix-school.com/l/1029621/2023-10-04/6j24";
